html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
b,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  color: #292f35 !important;
  width: 100vw !important;
  overflow-x: hidden;
  background-color: white;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::selection {
  background-color: #4141e12c;
}

.bold {
  font-weight: 700;
}

.oblique {
  font-style: oblique;
}

.ajust-toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.ajust-toast .alert {
  background-color: #fd7e14 !important;
  color: white !important;
  margin-bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ajust-toast .erro {
  background-color: #dc3545 !important;
  color: white !important;
  margin-bottom: 0;
}

.ajust-toast .success {
  background-color: #198754 !important;
  color: white !important;
  margin-bottom: 0;
}

.ajust-toast .btn-close {
  filter: invert(1);
}

.dot {
  width: 7px;
  height: 7px;
  background-color: #4141e1;
  border-radius: 100%;
}

/*

**** color palette ****

color-light: #f8f9fa;
color-base: #eff2f5;
color-mid: #bcc1c6;
color-bold: #5f6b79;
color-dark: #292f35;
color-accent: #4141e1;

*/
