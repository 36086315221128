.box-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  border: solid 1px hsl(210, 8%, 76%);
  border-style: dashed;
}

.box-profile-bkg {
  background-color: #f8f9fa;
}

.ct-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.profile-info {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.client-name {
  display: flex;
  align-items: center;
}

.client-name h1 {
  font-size: 18px;
}

.verified img {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-right: 0;
}

.title-label {
  font-weight: 300;
  color: #5f6b79;
  margin-bottom: 4px;
}

.divider-profile {
  height: 25px;
  width: 1px;
  background-color: #bcc1c6;
}

.btn-profile {
  display: flex;
}

.edit-profile {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.edit-profile img {
  width: 16px;
  height: 16px;
}

.profile-datail {
  display: none;
}

.profile-datail div {
  display: flex;
  flex-direction: column;
}

.mid-div {
  margin: 0 18px;
}

.status-profile {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.dot-red {
  background-color: #dc3545 !important;
  margin-right: 4px;
}

.dot-green {
  background-color: #198754 !important;
  margin-right: 4px;
}

.dot-yellow {
  background-color: #fd7e14 !important;
  margin-right: 4px;
}

.value-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
}

.price,
.small-label,
.value-label {
  display: flex;
  align-items: center;
}

.value-label h4 {
  font-size: 16px;
  margin-right: 6px;
}

.price {
  font-size: 24px;
  font-weight: 900;
  margin-right: 6px;
  margin-bottom: 14px;
}

.price-yad {
  margin-bottom: 14px;
}

.price img {
  width: 14px;
  height: 14px;
}

.small-label p {
  font-size: 14px;
}

.small-label {
  margin-left: 8px;
}

.see-value,
.small-label {
  cursor: pointer;
}

.wallet-hash {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
}

.label-wallet {
  margin-right: 2px;
}

.truncat-wallet {
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.card-header-btn {
  display: flex;
  cursor: pointer;
  padding: 16px 16px 16px 24px;
}

.card-header-btn:hover {
  background-color: #f8f9fa;
  border-radius: 15px;
}

.card-header-btn p {
  margin: 0;
  margin-left: 6px;
}

.card-content {
  padding: 16px 16px 16px 24px;
}

.caret-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate-up {
  transform: rotate(180deg);
}

.rotate-down {
  transform: rotate(0deg);
}

@media (max-width: 575.98px) {
  .hide-mobile {
    display: none;
  }

  .profile-datail {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 15px;
    border: solid 1px #bcc1c6;
    border-style: dashed;
    margin-bottom: 24px;
  }

  .truncat-wallet {
    direction: rtl;
  }

  .truncat-wallet strong {
    direction: ltr;
  }

  .label-wallet {
    white-space: nowrap;
  }
}
