nav {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 18px 28px !important;
}

.triangle {
  display: flex;
  align-items: center;
}

.triangle img {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.right-nav-on {
  display: flex;
  align-items: center;
}

.btn-nav {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.exit {
  margin: 0 8px;
}

.menu-inside img,
.exit img,
.bell img {
  width: 18px;
  height: 18px;
}

.exit:hover,
.menu-inside:hover,
.bell:hover {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.menu-inside:hover img,
.exit:hover img,
.bell:hover img {
  width: 20px;
  height: 20px;
  transition: ease-in-out 0.2s;
}

.client {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff2f5;
  border-radius: 100%;
  cursor: pointer;
  border: solid 1px #292f35;
  border-style: dashed;
  overflow: hidden;
  margin-left: 8px;
}

.client:hover {
  border: solid 1px #4141e17a;
  border-style: dashed;
}

.client:hover img {
  width: 95%;
  height: 95%;
}

.bell img {
  margin-top: 15px;
}

.notify {
  z-index: 1;
  position: relative;
}

.bell .rounded-circle {
  width: 20px;
  height: 20px;
  background-color: #4141e1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  z-index: 2;
}

.right-nav-off {
  display: flex;
  align-items: center;
}

.divider {
  padding: 0px 12px;
}

.menu img {
  width: 16px;
  height: 16px;
}

.language div,
.account,
.menu {
  cursor: pointer;
}

.offc-h-r,
.offcanvas-title,
.language {
  display: flex;
}

.title-brand {
  display: flex;
  align-items: center;
}

.title-brand img {
  margin-right: 6px;
}

.language {
  margin-right: 24px;
}

.fr,
.en {
  margin-right: 24px;
}

.language-active {
  font-weight: 900;
  color: #4141e1;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
  color: #4141e1;
}

.accordion-button::after {
  background-image: url(../../assets/icons/chevron-down.svg);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/icons/chevron-down-active.svg);
}

.btn-close:focus,
.accordion-button:focus {
  border: 0;
  box-shadow: none;
}

.accordion-header button {
  font-weight: 700;
}

.accordion-list-date {
  margin-top: 16px;
}

.coins-ct {
  margin-top: 0 !important;
}

.accordion-list-date p {
  padding: 6px 0;
}
.accordion-p-two,
.accordion-p-one {
  line-height: 18px;
}

.accordion-p-two {
  margin: 12px 0;
}

.contract {
  display: flex;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  direction: rtl;
}

.truncate strong {
  direction: ltr;
}

.accordion-buttons button {
  width: 100%;
  margin-top: 14px;
}

.tools,
.dex {
  display: flex;
}

.tools {
  display: flex;
  justify-content: space-between;
}

.dex button {
  border: 0;
  width: 100%;
}

.tools button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.dex button:hover {
  background-color: transparent !important;
}

.tools button img {
  scale: 1.1;
}

.tools button:hover img {
  scale: 1.3;
  filter: grayscale(0%);
  transition: ease-in-out 0.3s;
  filter: invert(1);
}

.dex button:hover img {
  scale: 1.2;
  filter: grayscale(0%);
  transition: ease-in-out 0.3s;
}

.dex button img {
  width: 30px;
  height: 30px;
  filter: grayscale(100%);
}

.center-tools {
  display: flex;
  align-items: center;
}

.center-tools img {
  margin-right: 8px;
}

.copied {
  background-color: #198754 !important;
  border-color: #198754 !important;
  color: white !important;
}

.btn-outline-dark.copied:hover {
  background-color: #157347 !important;
  border-color: #157347 !important;
}

.notify-on {
  background-color: #f8f9fa;
  cursor: pointer;
}

.notify {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
}

.notify small {
  font-size: smaller;
}

.time-notify {
  margin: 12px 0px;
}

.btn-notify {
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  border: solid 1px #292f35;
}

.btn-notify:hover {
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  border: solid 1px #292f35;
  background-color: #4141e1;
  color: white;
}

.notify h6 {
  display: flex;
  align-items: center;
  font-weight: bolder;
  margin-bottom: 16px;
}

.activated {
  color: #4141e1;
}

.spinner-notify {
  margin-right: 8px;
}

@media (max-width: 575.98px) {
  .offcanvas {
    width: 100%;
  }

  .client {
    width: 50px;
    height: 50px;
    overflow: hidden;
  }

  .client img {
    width: 100%;
    height: 100%;
  }
}
