.margin-right-24 {
  margin-right: 24px;
}

.margin-right-28 {
  margin-right: 28px;
}

.margin-right-48 {
  margin-right: 48px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-top-24 {
  margin-top: 24px;
}

.padding-24 {
  padding: 24px;
}
