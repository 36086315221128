.ct-access {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand {
  cursor: pointer;
}

.brand:hover {
  opacity: 0.7;
}

.form-floating input {
  border: 0 !important;
  padding: 0 !important;
  background-color: transparent;
}

.form-control:focus {
  background-color: transparent;
}

.form-floating input {
  border-bottom: solid 2px #292f35 !important;
  border-radius: 0 !important;
  padding-left: 4px !important;
  margin-top: 100px;
}

.password-login {
  margin-top: 24px !important;
}

.label-password {
  margin-top: 28px;
}

.form-floating input:focus {
  padding-left: 4px !important;
  box-shadow: none;
}

.form-floating > label {
  padding: 10px 0 0 0 !important;
}

.form-control {
  width: 400px;
}

.access h1 {
  font-size: 24px;
  font-weight: 300;
  color: #4141e1;
  margin-top: 18px;
}

.access strong {
  font-weight: 900;
}

.login-indication {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}

.step-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-indication p {
  font-size: 16px;
  cursor: pointer;
  color: #bcc1c6;
}

.login-indication p:hover {
  font-size: 20px;
  transition: ease-in-out 0.3s;
  font-weight: 900;
  color: #4141e1;
  opacity: 0.7;
}

.active-btn p {
  color: #4141e1;
  font-size: 20px;
  font-weight: 900;
}

.label-links {
  color: #5f6b79;
  line-height: 20px;
  font-weight: 300;
}

.links {
  text-decoration: underline;
  color: #4141e1;
  cursor: pointer;
  font-weight: 400;
}

.terms {
  width: 400px;
  margin-top: 60px;
}

.support {
  width: 400px;
  margin-top: 60px;
}

.btn-outline-dark {
  border-color: #292f35 !important;
  color: #292f35 !important;
  transition: ease-in 0.2s;
}

.btn-outline-dark:focus,
.btn-outline-dark:active {
  box-shadow: none !important;
}

.btn-outline-dark:hover {
  background-color: #4141e1 !important;
  border-color: #4141e1 !important;
  color: white !important;
}

button {
  margin-top: 28px;
  height: 44px;
  width: 400px;
  border-radius: 8px !important;
}

button:disabled {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.carousel {
  width: 400px !important;
  margin-top: 68px;
}

.ct-img {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.image-item {
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 5px;
}

.image-item.selected {
  border: 3px solid #4141e1;
  border-radius: 100%;
  transform: scale(1.1);
}

.image-item:hover {
  transform: scale(1.3);
  border: 3px solid #bcc1c6;
  border-radius: 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 0 .708l6 6a.5.5 0 0 0 .708-.708L5.707 8l5.647-5.646a.5.5 0 0 0 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.ct-terms {
  margin-top: 97px;
}

.ct-terms label {
  margin-left: 8px;
}

.ct-terms .second-check {
  margin-top: 12px;
}

.ct-terms .form-check {
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
}

.form-check-input:checked {
  background-color: #4141e1;
  border-color: #4141e1;
}

.form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
}

.form-switch .form-check-input:focus {
  background-image: url("../../assets/img/circle.svg");
  border: 0;
  box-shadow: none;
}

.eye {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  bottom: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.eye:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
}

.dialog-box-body {
  width: 400px;
  margin-top: 60px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  font-weight: 300;
}

.success {
  display: flex;
  align-items: center;
}

.success img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.main-content {
  margin-top: 16px;
}

@media (max-width: 575.98px) {
  .access {
    width: 100%;
    padding: 10px 20px;
  }

  form {
    width: 100%;
  }

  .form-control {
    width: 100%;
  }

  .login-indication {
    width: 100%;
  }

  .terms {
    width: 100%;
  }

  button {
    width: 100%;
  }

  .carousel {
    width: 100% !important;
  }

  .dialog-box-body {
    width: 100%;
  }

  .support {
    width: 100%;
  }
}
